.border-primary {
  border: 1px solid var(--bs-primary);
}

.CalendarDay__selected {
  background: var(--bs-primary);
  color: var(--bs-secondary);
  @extend .border-primary;
}

.CalendarDay__selected:hover {
  background: var(--bs-primary);
  @extend .border-primary;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: var(--bs-link-color);
  color: var(--bs-secondary);
  @extend .border-primary;
}

.CalendarDay__selected_span,
CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: var(--bs-link-color);
  @extend .border-primary;
}
