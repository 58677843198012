// Colour variables used: $primary, $white
.pagination {
  .pagination-wrapper {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
  }

  .separator {
    width: 1rem;
    margin: 0 0.25rem;
    margin-top: 0.3rem;
  }

  .page-item {
    background: transparent;
    border: none;
    height: 2rem;
    width: 2rem;
    margin: 0 0.25rem;
    border-radius: 50%;
    font-weight: 500;
    font-size: 12px;
    padding: 0;

    &:hover {
      background-color: #c3e9ec;
    }

    &:focus {
      outline: 0;
    }
  }

  .active {
    background-color: var(--bs-primary) !important;
    color: var(--bs-white);
  }

  .sides {
    font-size: 14px;

    &:hover {
      text-decoration: none;
    }
  }
}
