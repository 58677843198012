.loader-parent {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 90%);
  z-index: 2;

  & > div {
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
