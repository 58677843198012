.row {
  width: 100%;
  margin: 0;
}

.nk-header-sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  min-width: 320px;
}

.dashboard-card-height {
  height: 270px;
}

@media (min-width: 576px) {
  .modal-footer {
    padding: 0.5rem 1.5rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.invoice-wrap {
  border: none;
  padding: 0;
}
