$calendar-dimensions: 47px;
$full-view-color: var(--bs-primary);
$primary: var(--bs-primary);
$partial-view-color: rgba(var(--bs-primary-rgb), 0.6);

.min-h-100vh {
  min-height: 100vh;
}

.h-60vh {
  height: 60vh;
}

.default-radius {
  border-radius: var(--bs-card-inner-border-radius);
}

.accordion-collapse {
  padding: 1rem;
}

.has-sub {
  .nk-menu-sub {
    max-height: 70vh;
    overflow: auto;
  }
}

.max-h-800px {
  max-height: 800px;
}

.radius-5px {
  border-radius: 5px;
}

.popover-image {
  box-shadow: 1px 1px 14px 4px #eeeeee;
  width: 100%;
  margin-bottom: 1rem;
  @extend .default-radius;
}

.preview-btn {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 0;
  background: var(--bs-warning);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 1px 1px 14px 4px #eeeeee;
  @extend .center-items-flex;
}

.pb-15px {
  padding-bottom: 15px;
}

.icon-primary-darker {
  color: rgba(var(--bs-primary-rgb), 1) !important;
}

.icon-primary-lighter {
  color: rgba(var(--bs-primary-rgb), 0.8) !important;
}

.pt-15px {
  padding-top: 15px;
}

.calendar-scroller {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--bs-gray-700);
    cursor: grab;
    border-radius: 25px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--bs-gray-600);
  }
}

.calendar-height {
  height: $calendar-dimensions;
}

.hasScroll {
  .table-calendar-date.border {
    width: calc($calendar-dimensions + 8px);
  }
}

.calendar-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;

  .box {
    border-radius: 5px;
    width: 20px;
    height: 20px;
    margin-right: 0.2rem;

    &.PARTIAL {
      background: $partial-view-color;
    }

    &.FULL {
      background: $full-view-color;
    }
  }
}

.border-dashed {
  border-style: dashed !important;
}

ul.list-style-points {
  list-style: initial;

  li {
    list-style: initial;
  }
}

.bg-soft-primary {
  background: rgb(var(--bs-primary-rgb), 0.1);
}

.reversed-scroll {
  transform: rotateX(180deg);

  .child {
    transform: rotateX(180deg);
  }
}

.table-calendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;

  .table-calendar-date {
    padding: 0.75rem;
    cursor: pointer;
    overflow: hidden;
    height: $calendar-dimensions;
    width: 100%; // 100% only if we have scroll

    &.isWeekend:not(.selected) {
      background: rgba(var(--bs-lighter-rgb), var(--bs-bg-opacity));
    }

    &:not(.disabled) {
      background: #ffffff;

      &:hover {
        background: #00000017;
        transition: 0.3ms;
      }

      &.selected {
        color: #ffffff;
      }

      &.PARTIAL {
        background: $partial-view-color;
      }

      &.FULL {
        background: $full-view-color;
      }

      &.invalid {
        background: var(--bs-danger);
      }
    }
  }

  :not(:last-child) {
    border-right: none;
  }
}

.overview-sticky-card {
  position: sticky;
  top: 140px;
}

.carousel-root {
  width: 100%;
}

.border-right {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.form-control-slider {
  accent-color: $primary;
}

.calendar-icon-image {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  border-radius: 7px;
}

.grid-column-1 {
  grid-column: 1/-1;
}

.login-bg-image {
  width: 100%;
  display: block;
  height: 100vh;
  object-fit: cover;
  object-position: center bottom;
}

.bg-success-light {
  background: #1ee0ac69;
}

.bg-muted-light {
  background: #c8cad0b0;
}

.line-item-form {
  [id^='react-select-'] {
    max-height: 100px;

    div {
      max-height: 100px;
    }
  }
}

.stepper-navigation {
  @extend .cursor-pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  border-radius: 0.75rem;
  background: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));

  &:hover,
  &.active {
    background: #364a63;
    color: #ffffff;
    transition: 0.2s ease-in;
  }
}

.nk-notification-item {
  @extend .cursor-pointer;
}

.language-flag {
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.user-avatar {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.custom-select {
  height: 7rem;
}

.aspect-ratio-1-05 {
  aspect-ratio: 1/0.5;
}

.chat-header-scroll {
  height: 20vh;
}

.chat-body-scroll {
  max-height: 45vh;
  overflow: auto;
}

.chat-sidebar-scroll {
  max-height: 65vh;
  overflow: auto;
}

.form-react-select ~ .invalid {
  color: #e85347;
  font-size: 11px;
  font-style: italic;
}

.w-fit {
  width: fit-content;
}

.country-select {
  width: 30px;
  aspect-ratio: 1/1;
  margin-right: 0.5rem;
  object-fit: cover;
}

.user-select {
  border-radius: 50%;
  @extend .country-select;
}

.campaign-table tr {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 7px;
  border-spacing: 0 1rem;
}

.flag-sm {
  width: 30px;
  object-fit: cover;
  aspect-ratio: 1/0.7;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
}

.user-avatar-sm,
.user-avatar.sm {
  height: 30px !important;
  width: 30px !important;
  font-size: 12px !important;
}

.img-cover {
  object-fit: cover;
}

.table-image {
  object-fit: cover;
  height: 65px;
  width: 100px;
  @extend .default-radius;
}

@mixin center-items-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fs-25px {
  font-size: 25px;
}

.icon-status-info {
  position: relative;

  .notification-position {
    position: absolute;
    top: -20%;
    left: 50%;
  }

  .notification-number {
    font-size: 0.5rem;
    background: var(--bs-primary);
    color: #ffffff;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.center-items-flex {
  @include center-items-flex;
}

.calendar-view-button {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  border-radius: 8px;

  &.day {
    background: $full-view-color;

    em {
      color: #ffffff;
    }
  }

  &.month {
    background: $full-view-color;

    em {
      color: #ffffff;
    }
  }

  &.week {
    background: $full-view-color;

    em {
      color: #ffffff;
    }
  }
}

.dots-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.min-h-65px {
  min-height: 65px !important;
}

.col-10 .preview-item .btn {
  padding: 0;
}

.dropzone {
  width: 100%;
  @include center-items-flex;
}

.border-right-none {
  border-right: none !important;
}

.nk-quick-nav-icon {
  font-size: 1rem;
  height: 30px;
  width: 30px;
}

.popover {
  width: 250px;
  max-width: 250px;
}
